import React from "react";
import * as dayjs from "dayjs";

export const showUserHistoryLog = (tableData, userInfo) => {
  return (
    <>
      <thead>
        <tr>
          <th>Transaction type</th>
          <th>Transaction date</th>
          <th>Kudos amount</th>
          <th>Reason</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(
          ({
            transactionId,
            transactionType,
            transactionDate,
            sendersId,
            amount: amountOfKudos,
            reason,
          }) => {
            if (transactionType === 3 || (transactionType === 1 && userInfo.id === sendersId)) {
              amountOfKudos = `-${amountOfKudos}`;
            }
            return (
              <tr key={transactionId}>
                <td>{getTransactionType(transactionType)}</td>
                <td>
                  {dayjs(transactionDate).format("dddd, MMM D, YYYY h:mm A")}
                </td>
                <td>{amountOfKudos}</td>
                <td>{reason}</td>
              </tr>
            );
          }
        )}
      </tbody>
    </>
  );

  function getTransactionType(type) {
    switch(type) {
        case 1:
            return "Person to Person";
        case 2:
            return "Request kudos";
        case 3:
            return "Purchase";
        default:
            return "Unknown";
    }
  }
};
