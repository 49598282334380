
import React, { Component } from 'react';
import { book } from '../../navigation/book';
import { Link } from 'react-router-dom';
import {default as pageNotFoundImg } from '@vismaux/nordic-cool/dist/img/error-pages/page_not_found.svg';

export class Unknown extends Component {
    render() {
        return (
            <>
            <main className="container">
                <div className="row error-page align-items-center">
                    <div className="error-page-content col-12 col-md-6">
                        <h1>Page not found</h1>
                        <p>We're sorry, the page you were looking for could not be found.
                             It may have moved or the address may be mistyped. Follow home page link below.</p>
                        <p className="error-page-home-link">
                            <Link to={book.users} className="btn btn-primary">Go to home page</Link>
                        </p>
                    </div>
                    <div className="error-page-image col-12 col-md-6">
                        <img src={pageNotFoundImg} alt="Page not found" />
                    </div>
                </div>
                </main>
            </>
        )
    }
};