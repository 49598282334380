import React, { Component } from "react";
import { CustomTable } from "../../components";
import { PageTitle, LoadingSpinner } from "../../common";
import { useApiRequests, showAdminHistoryLog } from "../../services";

export class AdminHistoryLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminActionsHistory: [],
      isDataFetching: true,
    };
  }

  async componentDidMount() {
    const { getAdminHistoryLog } = useApiRequests();
    const response = await getAdminHistoryLog();
    this.setState({
      adminActionsHistory: response.data,
      isDataFetching: false,
    });
  }

  render() {
    const showAdminHistoryLogJSX = showAdminHistoryLog(
      ["Operation ID", "Date of operation", "Operation description"],
      this.state.adminActionsHistory
    );

    const renderAdminHistoryLogJSXData = this.state.isDataFetching ? (
      <LoadingSpinner message="Loading..." />
    ) : (
      <>
        <PageTitle title="Admin's History Log" />
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="custom-shadow">
            <CustomTable customClassName="table table-custom no-margin table-hover table-active p-0">
              {showAdminHistoryLogJSX}
            </CustomTable>
          </div>
        </div>
      </>
    );
    return <>{renderAdminHistoryLogJSXData}</>;
  }
}
