import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ShopSubmitBtn, CustomModal } from "../../common";
import { useApiRequests } from "../../services";

export class ProductChoiceTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProductForPurchase: "",
    };
  }

  onProductChange = (event, value) => {
    this.setState({
      selectedProductForPurchase: value,
    });
  };

  handlePurchaseSubmit = () => {
    this.props.handleModalToggle();
    this.props.clearInputs();
    const { postRegisterPurchase } = useApiRequests();
    postRegisterPurchase(
      this.state.selectedProductForPurchase.id
    );
  };

  handleResetForm = () => {
    this.setState({
      selectedProductForPurchase: "",
    });
  };

  render() {
    return (
      <>
        <div className="form-group d-sm-flex justify-content-start align-items-center">
          <label className="no-padding col-4">Product</label>
          <Autocomplete
            id="combo-box-demo1"
            style={{ width: 350 }}
            options={this.props.kudosItems}
            onChange={this.onProductChange}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(option) => {
              return <h4>{option.name}</h4>;
            }}
          />
        </div>
        <ShopSubmitBtn
          handleModalToggle={this.props.handleModalToggle}
          title="Submit"
          className={
            this.state.selectedProductForPurchase
              ? `btn btn-primary`
              : `btn disabled`
          }
        />
        <CustomModal
          handleModalToggle={this.props.handleModalToggle.bind(this)}
          isModalOpen={this.props.isModalOpen}
          handleModalClose={this.props.handleModalClose}
          handleSubmitForm={this.handlePurchaseSubmit}
          message="Are you sure you want to submit this purchase?"
        />
      </>
    );
  }
}
