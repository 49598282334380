﻿import React, { Component } from "react";
import "./styles.scss";
export class Footer extends Component {
  render() {
    return (
      <footer className="copyright blue-50">
              <span>
                &copy; {new Date().getFullYear()} Visma Labs Kudoshop
              </span>
      </footer>
    );
  }
}