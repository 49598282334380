import React from "react";

export const showUserInformation = (
  columnTitles,
  { name, surname, amountOfKudos }
) => {
  return (
    <>
      <thead>
        <tr>
          {columnTitles.map((columnTitle, index) => (
            <th key={index}>{columnTitle}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{name}</td>
          <td>{surname}</td>
          <td>{amountOfKudos}</td>
        </tr>
      </tbody>
    </>
  );
};
