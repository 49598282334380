import React, { Component } from "react";
import { TransactionShopForm } from "../../components";
import { PageTitle, LoadingSpinner } from "../../common";
import { NotificationPopup } from "../../common/NotificationPopup";
import { useApiRequests } from "../../services";
import "./styles.scss";

export class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kudosItems: [],
      userData: [],
      isDataFetching: true,
      isModalOpen: false,
      isLoading: false,
      isPopupOpen: false,
    };
  }

  async componentDidMount() {
    const { getAllShoppingPageInformation } = useApiRequests();
    const allShoppingPageInformationResponse = await getAllShoppingPageInformation();
    this.setState({
      kudosItems: allShoppingPageInformationResponse.allNonHiddenShoppingItemsInformation,
      userData: allShoppingPageInformationResponse.allUsersInformation,
      isDataFetching: false,
    });
  }

  render() {
    const showSuccessAlertJSX = this.state.isPopupOpen && (
      <NotificationPopup message="Transaction is successfull!" />
    );
    const showLoadingSpinnerJSX = this.state.isLoading && <LoadingSpinner />;
    const showShoppingListJSX = this.state.isDataFetching ? (
      <LoadingSpinner message="Loading..." />
    ) : (
      <>
        <PageTitle title="Choose the product or send the kudos to the employee" />
        <TransactionShopForm
          userData={this.state.userData}
          kudosItems={this.state.kudosItems}
        />
        {showSuccessAlertJSX}
        {showLoadingSpinnerJSX}
      </>
    );

    return <>{showShoppingListJSX}</>;
  }
}
