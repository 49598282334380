import React, { Component } from "react";

export class CustomSelect extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { optionTypes, userType } = this.props;

    const renderOptionsJSX = Object.keys(optionTypes).map((key) => {
      // Check if the option is "Request Kudos" and user type is not admin, then skip rendering
      if (key === "requestKudos" && userType == 1) {
        return null;
      }
      if (key === "sendKudosAsAdmin" && userType == 2) {
        return null;
      }
      return (
        <option key={key} value={key}>
          {optionTypes[key]}
        </option>
      );
    });

    return (
      <select className="form-control mb-8" defaultValue="" onChange={this.props.handleSelectChange}>
        <option value="">Make a choice ...</option>
        {renderOptionsJSX}
      </select>
    );
  }
}
