import React from "react";
import * as dayjs from "dayjs";

export const showAdminHistoryLog = (theads, tableData) => {
  return (
    <>
     <thead>
       <tr>
     {theads.map((heading, index) => {
            return <th key={index}>{heading}</th>;
          })}
          </tr>
             
          </thead>
          <tbody>
            {tableData.map(
              ({ operationId, operationDate, operationDescription }) => {
                return (
                  <tr key={operationId}>
                    <td>{operationId}</td>
                    <td>{dayjs(operationDate).format("dddd, MMM D, YYYY h:mm A")}</td>
                    <td>{operationDescription}</td>
                  </tr>
                );
              }
            )}
          </tbody>
    </>
  );
};
