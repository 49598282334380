import React, { Component } from "react";
import "./styles.scss";

export class LoadingSpinner extends Component {
  render() {
    const { message } = this.props;
    return (
      <div className="d-flex flex-column align-items-center justify-content-center loader">
        <span className="spinner spinner-primary-blue text-center"></span>
        {message && <span className="pt-8 text-center">{message}</span>}
      </div>
    );
  }
}
