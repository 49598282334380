import React, { Component } from "react";
import { PageTitle, LoadingSpinner, CustomModal } from "../../common";
import { FormAddProduct, FormEditProduct } from "../../components";
import { useApiRequests } from "../../services";
import "./styles.scss";
import img from "../../img/preview_img.svg";

export class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allProducts: [],
      isDataFetching: true,
      isLoading: false,
      selectedProduct: [],
      newProductName: "",
      newProductPrice: 0,
      newProductComment: "",
      newProductImageUrl: "",
      newProductIsAvailable: false,
      isEditFormOpenned: false,
      isFormOpenned: true,
      isModalOpen: false,
      userType: null,
      currentUserType: localStorage.getItem('userType')
    };
    this.fetchProductsData = this.fetchProductsData.bind(this);
    this.onNewProductNameChange = this.onNewProductNameChange.bind(this);
    this.onNewProductPriceChange = this.onNewProductPriceChange.bind(this);
    this.onNewProductCommentChange = this.onNewProductCommentChange.bind(this);
    this.onNewProductImageUrlChange = this.onNewProductImageUrlChange.bind(this);
    this.onNewProductIsAvailableChange = this.onNewProductIsAvailableChange.bind(
      this
    );
    this.handleResetForm = this.handleResetForm.bind(this);
    this.handleProductEditForm = this.handleProductEditForm.bind(this);
    this.handleProductEdit = this.handleProductEdit.bind(this);
    this.handleModalToggle = this.handleModalToggle.bind(this);
    this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
    this.handleProductDelete = this.handleProductDelete.bind(this);
  }

  async fetchProductsData() {
    const { getAllProducts } = useApiRequests();
    const response = await getAllProducts();
    this.setState({
      allProducts: response.data,
      isDataFetching: false,
      isLoading: false,
    });
  }

  componentDidMount() {
    this.fetchProductsData();
  }

  onNewProductNameChange(event, value) {
    this.setState({
      newProductName: event.target.value,
    });
  }

  onNewProductPriceChange(event, value) {
    this.setState({
      newProductPrice: event.target.value,
    });
  }

  onNewProductCommentChange(event, value) {
    this.setState({
      newProductComment: event.target.value,
    });
  }

  onNewProductImageUrlChange(event, value) {
    this.setState({
      newProductImageUrl: event.target.value,
    });
  }

  onNewProductIsAvailableChange(event, value) {
    if (event.target.value === "Yes") {
      this.setState({
        newProductIsAvailable: true,
      });
    } else if (event.target.value === "No") {
      this.setState({
        newProductIsAvailable: false,
      });
    }
  }

  handleResetForm() {
    this.setState({
      newProductName: "",
      newProductPrice: 0,
      newProductComment: "",
      newProductIsAvailable: false,
      newProductImageUrl: "",
    });
  }

  handleCloseEditForm() {
    this.setState({
      isEditFormOpenned: false,
      isFormOpenned: true,
    });
  }

  handleProductEditForm(e, product) {
    this.setState({
      isEditFormOpenned: true,
      isFormOpenned: false,
      newProductName: product.name,
      newProductPrice: product.price,
      newProductComment: product.comment,
      newProductIsAvailable: product.isAvailable,
      newProductImageUrl: product.imageUrl,
      selectedProduct: product,
      selectedProductId: null,
    });
  }

  handleModalToggle(event) {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  handleProductEdit = async (e) => {
    this.setState({ selectedProductId: null });
  
    try {
      const apiRequests = useApiRequests(); // Use the useApiRequests function to get API functions
  
      const response = await apiRequests.editProduct(
        this.state.selectedProduct.id,
        this.state.newProductName,
        this.state.newProductPrice,
        this.state.newProductComment,
        this.state.newProductIsAvailable,
        this.state.newProductImageUrl
      );
  
      if (response.status === 200) {
        this.setState({ isLoading: true, isModalOpen: false }, () => {
          setTimeout(() => {
            this.setState({
              isLoading: false,
              isEditFormOpenned: false,
              isFormOpenned: true,
              selectedProduct: [],
            });
            this.fetchProductsData();
          }, 700);
        });
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  handleProductDelete = async (e) => {
    if (this.state.selectedProductId) {
      try {
        const apiRequests = useApiRequests(); // Use the useApiRequests function to get API functions
  
        const response = await apiRequests.deleteProductById(this.state.selectedProductId);
  
        if (response.status === 200) {
          this.setState({ isLoading: true, isModalOpen: false }, () => {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                isEditFormOpenned: false,
                isFormOpenned: true,
                selectedProduct: [],
              });
              this.fetchProductsData();
            }, 200);
          });
        } else {
          // Handle error if needed
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  }

  handleDeleteProductModal(e, id) {
    this.setState({
      selectedProduct: [],
      selectedProductId: id,
      isModalOpen: true,
    });
  }

  render() {
    const renderProductListJSX = this.state.isDataFetching ? (
      <LoadingSpinner message="Loading..." />
    ) : (
      <>
        <PageTitle title="Visma Products" />
        <div className="col-10">
          <div className="shadow-50 d-flex flex-column justify-content-center align-items-center">
            {this.state.isLoading && !this.state.allProducts ? (
              <LoadingSpinner />
            ) : (
              <>
                {this.state.isFormOpenned && this.state.currentUserType == 1 && (
                    <FormAddProduct
                      fetchData={this.fetchProductsData}
                      isLoading={this.state.isLoading}
                    />
                )}
                {/* Update the conditions for rendering the FormEditProduct */}
                {!this.state.isFormOpenned && this.state.currentUserType == 1 && (
                    <FormEditProduct
                      selectedProduct={this.state.selectedProduct}
                      newProductName={this.state.newProductName}
                      newProductImageUrl={this.state.newProductImageUrl}
                      newProductPrice={this.state.newProductPrice}
                      newProductComment={this.state.newProductComment}
                      newProductIsAvailable={this.state.newProductIsAvailable}
                      onNewProductNameChange={this.onNewProductNameChange}
                      onNewProductPriceChange={this.onNewProductPriceChange}
                      onNewProductCommentChange={this.onNewProductCommentChange}
                      onNewProductImageUrlChange={this.onNewProductImageUrlChange}
                      onNewProductIsAvailableChange={this.onNewProductIsAvailableChange}
                      handleResetForm={this.handleResetForm}
                      fetchData={this.fetchProductsData}
                      handleModalToggle={this.handleModalToggle}
                      handleCloseEditForm={this.handleCloseEditForm}
                    >
                      <CustomModal
                        handleModalToggle={this.handleModalToggle}
                        isModalOpen={this.state.isModalOpen}
                        handleModalClose={this.handleModalClose}
                        handleSubmitForm={this.handleProductEdit}
                        message="Are you sure you want to change the information about this product?"
                      />
                    </FormEditProduct>
                )}
                
                {this.state.selectedProductId && this.state.currentUserType == 1 && (
                  <div>
                    <CustomModal
                      handleModalToggle={this.handleModalToggle}
                      isModalOpen={this.state.isModalOpen}
                      handleModalClose={this.handleModalClose}
                      handleSubmitForm={(e) => this.handleProductDelete(e)}
                      message="Are you sure you want to remove this product?"
                    />
                  </div>
                )}
  
                <div className="card-container mt-32 d-flex justify-content-center">
                  {this.state.allProducts
                    .filter((p) => p.comment !== "Deleted Product")
                    .map((product) => {
                      const { id, name, comment, price, imageUrl, isAvailable } = product;
                      return (
                        <div className="product-card col-2 p-0" key={id}>
                          <div className="card-body">
                            <h2 className="card-title pt-16">{name}</h2>
                            <p className="card-text-price pt-8">
                              <span className="pr-4">
                                <b>Price:</b>
                              </span>
                              {price} kudos points
                            </p>
                          </div>
                          <div className="test">
                            <p className="card-text-description">
                              Description:{" "}
                              {comment
                                ? comment
                                : "There is no description for this product"}
                              <br/>
                              {imageUrl && (
                                <a href={imageUrl} target="_blank" style={{ color: 'yellow' }}>
                                   Link to image
                                </a>
                              )}
                              {isAvailable ? null : (
                                <div>
                                  <br/>
                                  <span style={{color: 'red'}}>This product is not available</span>
                                </div>
                              )}
                            </p>
                            <div className="test-icons">
                              {/* Update the condition for rendering the buttons */}
                              {this.state.currentUserType == 1 && (
                                <>
                                  <span
                                    aria-hidden="true"
                                    className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-edit mr-8"
                                    onClick={(e) =>
                                      this.handleProductEditForm(e, product)
                                    }
                                  ></span>
                                  <span
                                    aria-hidden="true"
                                    className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-delete"
                                    onClick={(e) =>
                                      this.handleDeleteProductModal(e, id)
                                    }
                                  ></span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
    return <>{renderProductListJSX}</>;
  }
}
