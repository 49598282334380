import React, { Component } from "react";
import { useApiRequests } from "../../services/fetchApiServices/index";
import { CustomModal, NotificationPopup, LoadingSpinner } from "../../common";

export class FormAddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newUserName: "",
      newUserSurname: "",
      isModalOpen: false,
      isFormOpened: false,
      isLoading: this.props.isLoading,
    };
  }

  onNewUserNameChange = (event, value) => {
    this.setState({
      newUserName: event.target.value,
    });
  };

  onNewUserSurnameChange = (event, value) => {
    this.setState({
      newUserSurname: event.target.value,
    });
  };

  handleModalToggle = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  addNewUserButton = () => {
    this.setState({ isFormOpened: true });
  };

  handleNewUserButtonSubmit = () => {
    this.handleModalToggle();
  };

  handleResetForm = () => {
    this.setState({
      newUserName: "",
      newUserSurname: "",
    });
  };

  handleCloseForm = () => {
    this.setState({
      isFormOpened: false,
    });
    this.handleResetForm();
  };

  handleAddNewUser = async () => {
    try {
      const apiRequests = useApiRequests();

      const response = await apiRequests.postAddNewUser(
        this.state.newUserName,
        this.state.newUserSurname
      );

      if (response.status === 200) {
        this.props.fetchData();
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  handleSubmitForm = () => {
    this.handleResetForm();
    this.setState({ isLoading: true, isModalOpen: false }, () => {
      setTimeout(() => {
        this.setState({
          newUserName: "",
          newUserSurname: "",
          isLoading: false,
        });
      }, 700);
    });
    this.handleAddNewUser();
  };

  render() {
    return (
      <>
        {this.state.isFormOpened ? (
          <>
            <CustomModal
              handleModalClose={this.handleModalClose}
              handleSubmitForm={this.handleSubmitForm}
              isModalOpen={this.state.isModalOpen}
              message={`Do you want to add new employee: ${this.state.newUserName} ${this.state.newUserSurname}?`}
            />
            <div className="form-horizontal col-xxl-6 col-xl-7 col-md-8 col-sm-8 col-xs-11">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-xl-4 col-sm-5 col-xs-5 control-label"
                >
                  New Employee's Name:
                </label>
                <div className="col-xl-8 col-sm-7 col-xs-7">
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    id="name"
                    value={this.state.newUserName}
                    onChange={this.onNewUserNameChange}
                    placeholder="Person First Name"
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="surname"
                  className="col-xl-4 col-sm-5 col-xs-5 control-label"
                >
                  New Employee's Surname:
                </label>
                <div className="col-xl-8 col-sm-7 col-xs-7">
                  <input
                    className="form-control"
                    name="surname"
                    id="surname"
                    type="text"
                    value={this.state.newUserSurname}
                    onChange={this.onNewUserSurnameChange}
                    placeholder="Person Last Name"
                  />
                </div>
              </div>

              <div className="form-group d-flex justify-content-between justify-content-sm-end align-items-xs-around">
                <button
                  type="button"
                  className="btn"
                  onClick={this.handleResetForm}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={this.handleCloseForm}
                >
                  Close
                </button>
                <button
                  type="button"
                  className={
                    this.state.newUserSurname.length &&
                    this.state.newUserName.length
                      ? `btn btn-primary`
                      : `btn disabled`
                  }
                  onClick={this.handleModalToggle}
                >
                  Save
                </button>
              </div>
            </div>
            {this.state.isLoading && <LoadingSpinner />}
          </>
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.addNewUserButton}
          >
            Add new employee
          </button>
        )}
      </>
    );
  }
}
