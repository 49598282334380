import { Link } from 'react-router-dom';
import logo from '../../img/visma-logo.png';

export const Header = () => {
  let token = localStorage.getItem('token');
  let userType = localStorage.getItem('userType');

  return (
    <header
      className="navbar navbar-default"
      id="nc4navTopMain"
      role="navigation"
    >
      <div className="navbar-header">
        <div className="navbar-brand">
          <Link
            to="/users"
            role="button"
            aria-label="Kudos Shop"
            className="d-flex align-items-center justify-content-center"
          >
            <img src={logo} alt="Logo" width="100px" className="pr-8" />
            Kudos Shop
          </Link>
        </div>
      </div>
      <nav className="collapse navbar-collapse" aria-label="navbarNav">
        <ul className="nav navbar-nav nav-tabs navbar-right first-level d-flex justify-content-between">
          <li className="icon">
            <Link
              className="nav-item fl-menu-item"
              to="/"
              role="menuitem"
              aria-selected="false"
            >
              <span
                aria-hidden="true"
                className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-users pr-8"
              ></span>
            </Link>
          </li>
          <li className="icon">
            <Link
              className="nav-item fl-menu-item has-children"
              to="/products"
              role="menuitem"
              aria-selected="true"
            >
              <span
                aria-hidden="true"
                className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-shopping-cart pr-8"
              ></span>
            </Link>
          </li>
            <li className="icon">
              <Link
                className="nav-item fl-menu-item has-children"
                to="/transactions"
                role="menuitem"
                aria-selected="true"
              >
                <span
                  aria-hidden="true"
                  className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-price pr-8"
                ></span>
              </Link>
            </li>
          {userType == 1 && (
            <li className="icon">
              <Link
                className="nav-item fl-menu-item has-children"
                to="/requests"
                role="menuitem"
                aria-selected="true"
              >
                <span
                  aria-hidden="true"
                  className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-menu-circle pr-8"
                ></span>
              </Link>
            </li>
          )}
          {userType == 1 && (
            <li className="icon">
              <Link
                className="nav-item fl-menu-item has-children"
                to="/adminlog"
                role="menuitem"
                aria-selected="true"
              >
                <span
                  aria-hidden="true"
                  className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-employee pr-8"
                ></span>
              </Link>
            </li>
          )}
          {token !== null && token !== "" && (
            <li className="icon">
              <Link
                className="nav-item fl-menu-item has-children"
                to="/login"
                role="menuitem"
                aria-selected="true"
                onClick={() => {
                 localStorage.removeItem('token');
                 localStorage.removeItem('userType');
                }}
              >
                <span
                  aria-hidden="true"
                  className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-logout pr-8"
                ></span>
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};
