import React, { Component } from "react";

export class ShopSubmitBtn extends Component {
  render() {
    return (
      <button
        type="button"
        className={this.props.className}
        data-toggle="modal"
        data-target="#smallpModal"
        onClick={this.props.handleModalToggle}
      >
        Submit
      </button>
    );
  }
}
