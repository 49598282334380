import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { book } from "./book";
import { Views } from "../views";
import { Header, Footer, GoogleAuthForm } from "../components";
import {
  AdminHistoryLog,
  User,
  Transactions,
  Unknown,
  Home,
  Products,
  Requests,
} from "../pages";

export const Routes = () => (
  <>
    <Route component={Header} exact path={[book.default, book.products, book.admin, book.transactions, book.user, book.requests]} />
    <Route component={Footer} path={book.default} />
    <Switch>
    <Route exact path={book.default}>
        <Views.Facade>
          <Home />
        </Views.Facade>
      </Route>
      <Route exact path={book.users}>
        <Views.Facade>
        <Redirect to={book.default} />
        </Views.Facade>
      </Route>
      <Route
        path={book.user}
        render={(props) => (
          <Views.Facade>
            <User {...props} />
          </Views.Facade>
        )}
      />
      <Route exact path={book.products}>
        <Views.Facade>
          <Products />
        </Views.Facade>
      </Route>
      <Route exact path={book.transactions}>
        <Views.Facade>
          <Transactions />
        </Views.Facade>
      </Route>
      <Route exact path={book.admin}>
        <Views.Facade>
          <AdminHistoryLog />
        </Views.Facade>
      </Route>
      <Route exact path={book.requests}>
        <Views.Facade>
          <Requests />
        </Views.Facade>
      </Route>
      <Route exact path={book.login}>
        <Views.Facade>
          <GoogleAuthForm />
        </Views.Facade>
      </Route>
      <Route path={book.error404} component={Unknown} >
      <Views.Facade>
        <Redirect to={book.error404} />
      </Views.Facade>
      </Route>
    </Switch>
  </>
);
