import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./navigation";
import { history } from "./navigation/history";
import "./custom.scss";
import User from "./UserContext"; // Import the UserProvider

const App = () => (
  <Router history={history}>
    <User>
      <Routes />
    </User>
  </Router>
);

export default App;
