import React, { Component } from "react";

export class NotificationPopup extends Component {
  constructor(props) {
    super(props);
   
  }

  render() {
    const jsx = this.props.isPopupOpen ? (
      <div className="alert alert-success d-0" role="alert">
        <strong>{this.props.message}</strong>
      </div>
    ) : (
      <div />
    );
      return jsx;
  }

}
