export const book = Object.freeze({
  default: "/",
  login: "/login",
  users: "/users",
  user: "/users/:id",
  transactions: "/transactions",
  products: "/products",
  requests: "/requests",
  admin: "/adminlog",
  error404: "/404",
});
