import React, { Component } from "react";

export class FormEditProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isFormOpenned: false,
      isLoading: this.props.isLoading,
    };
  }
  render() {
    return (
      <>
        <div className="form-horizontal col-xxl-6 col-xl-7 col-md-8 col-sm-8 col-xs-11">
          <div className="form-group">
            <label
              htmlFor="name"
              className="col-xl-4 col-sm-5 col-xs-5 control-label"
            >
              Product Name:
            </label>
            <div className="col-xl-8 col-sm-7 col-xs-7">
              <input
                className="form-control"
                name="name"
                type="text"
                id="name"
                placeholder="Product Name"
                value={this.props.newProductName || ""}
                onChange={this.props.onNewProductNameChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="price"
              className="col-xl-4 col-sm-5 col-xs-5 control-label"
            >
              Product Price:
            </label>
            <div className="col-xl-8 col-sm-7 col-xs-7">
              <input
                className="form-control"
                name="price"
                id="price"
                type="number"
                placeholder="Product Price"
                value={this.props.newProductPrice || ""}
                onChange={this.props.onNewProductPriceChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="comment"
              className="col-xl-4 col-sm-5 col-xs-5 control-label"
            >
              Comment:
            </label>
            <div className="col-xl-8 col-sm-7 col-xs-7">
              <input
                className="form-control"
                name="comment"
                id="comment"
                type="text"
                maxLength={255}
                placeholder="Comment"
                value={this.props.newProductComment || ""}
                onChange={this.props.onNewProductCommentChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="image"
              className="col-xl-4 col-sm-5 col-xs-5 control-label"
            >
              Image Link:
            </label>
            <div className="col-xl-8 col-sm-7 col-xs-7">
              <input
                className="form-control"
                name="image"
                id="image"
                type="text"
                maxLength={255}
                placeholder="Image"
                value={this.props.newProductImageUrl || ""}
                onChange={this.props.onNewProductImageUrlChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="comment"
              className="col-xl-4 col-sm-5 col-xs-5 control-label"
            >
              Is Product Available?:
            </label>
            <div className="d-flex align-items-center justify-content-center">
              <label className="control-label">Yes</label>
              <input
                name="optionsRadios"
                id="optionsRadios1"
                value="Yes"
                type="radio"
                onChange={this.props.onNewProductIsAvailableChange}
                checked={this.props.newProductIsAvailable && "checked"}
                className="mr-16"
              />

              <label className="control-label">No</label>
              <input
                name="optionsRadios"
                id="optionsRadios2"
                value="No"
                type="radio"
                onChange={this.props.onNewProductIsAvailableChange}
                checked={!this.props.newProductIsAvailable && "checked"}
              />
            </div>
          </div>

          <div className="form-group d-flex justify-content-between justify-content-sm-end align-items-xs-around">
            <button
              type="button"
              className="btn"
              onClick={this.props.handleResetForm}
            >
              Reset
            </button>
            <button
              type="button"
              className="btn"
              onClick={this.props.handleCloseEditForm}
            >
              Close
            </button>
            <button
              type="button"
              className={
                this.props.newProductName && this.props.newProductPrice
                  ? `btn btn-primary`
                  : `btn disabled`
              }
              onClick={this.props.handleModalToggle}
            >
              <span
                aria-hidden="true"
                className="vismaicon vismaicon-edit"
              ></span>
              Edit and Save
            </button>
          </div>
          {this.props.children}
        </div>
      </>
    );
  }
}
