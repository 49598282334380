import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles.scss";
import '@vismaux/nordic-cool/dist/css/nc.min.css';
import * as dayjs from "dayjs";
import { GoogleOAuthProvider } from '@react-oauth/google';
dayjs().format();

  // ReactDOM.render(
  //     <App />,
  //   document.getElementById("root")
  // );

  ReactDOM.render(
    <GoogleOAuthProvider clientId="85917643625-5b1n9fi1am0u19hbjtn4okq13den5vkn.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>,
    document.getElementById('root')
  );
