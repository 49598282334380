import React, { Component } from "react";
import { PageTitle, LoadingSpinner, CustomModal } from "../../common";
import { useApiRequests } from "../../services";
import { renderRequestType } from "../../services/utils/presenter";
import "./styles.scss";

export class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allRequests: [],
      isDataFetching: true,
      isLoading: false,
      isModalOpen: false,
      currentUserType: localStorage.getItem('userType')
    };
    this.fetchRequestsData = this.fetchRequestsData.bind(this);
  }

  async fetchRequestsData() {
    const { getAllRequests } = useApiRequests();
    const response = await getAllRequests();
    this.setState({
      allRequests: response.data,
      isDataFetching: false,
      isLoading: false,
    });
  }

  componentDidMount() {
    this.fetchRequestsData();
  }

  handleModalToggle(event) {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  async handleApprove(requestType, id) {
    try {
      const apiRequests = useApiRequests(); // Use the useApiRequests function to get API functions

      const response = await apiRequests.approveRequestByTypeAndId(requestType, id);

      if (response.status === 200) {
        this.setState({ isLoading: true, isModalOpen: false }, () => {
          setTimeout(() => {
            this.setState({
              isLoading: false,
              isEditFormOpenned: false,
              isFormOpenned: true,
            });
            this.fetchRequestsData();
          }, 200);
        });
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log("Approved request with ID:", id, "and Request Type:", requestType);
  }

 async handleDecline(requestType, id) {
  try {
    const apiRequests = useApiRequests(); // Use the useApiRequests function to get API functions

    const response = await apiRequests.rejectRequestByTypeAndId(requestType, id);

    if (response.status === 200) {
      this.setState({ isLoading: true, isModalOpen: false }, () => {
        setTimeout(() => {
          this.setState({
            isLoading: false,
            isEditFormOpenned: false,
            isFormOpenned: true,
          });
          this.fetchRequestsData();
        }, 200);
      });
    } else {
      // Handle error if needed
    }
  } catch (error) {
    console.log(error.message);
  }
    // Implement your decline logic here
    console.log("Declined request with ID:", id, "and Request Type:", requestType);
  }

  renderRequestType(requestTypeId) {
    console.log(requestTypeId);
    if (requestTypeId === 1) {
      return <td>{"Person to person"}</td>;
    }
    if (requestTypeId === 2) {
        return <td>{"Product"}</td>;
    }
    if (requestTypeId === 3) {
      return <td>{"Kudos request"}</td>;
  } 
  }

  renderRequestDescription(request) {
    switch (request.requestTypeId) {
      case 1:
        return `${request.senderName} ${request.senderSurname}(${request.senderAmountOfKudos}) would like to send ${request.kudosToTransfer} kudos to ${request.receiverName} ${request.receiverSurname}(${request.receiverAmountOfKudos}) with reason: ${request.description}`;
      case 2:
        return `${request.receiverName} ${request.receiverSurname}(${request.receiverAmountOfKudos}) would like to buy ${request.productName} for ${request.productPrice} kudos`;
      case 3:
        return `${request.receiverName} ${request.receiverSurname}(${request.receiverAmountOfKudos}) would like to get ${request.kudosToTransfer} kudos because of: ${request.description}`;
      default:
        return "Unknown request type";
    }
  }

  renderRequestStatus(requestStatusId) {
    if (requestStatusId === 1) {
      return <td>{"Pending"}</td>;
    }else if(requestStatusId === 2){
      return <td>{"Approved"}</td>;
    }
     else {
      return <td>{"Rejected"}</td>;
    }
  }

  render() {
    const { isDataFetching, allRequests } = this.state;

    const renderRequestsTable = isDataFetching ? (
      <LoadingSpinner message="Loading..." />
    ) : (
      <>
        <PageTitle title="Requests" />
        <div className="col-10">
          <div className="shadow-50">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Request type</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allRequests.map(request => (
                  <tr key={request.uniqueId}>
                    <td>{request.date}</td>
                    {this.renderRequestType(request.requestTypeId)}
                    <td>{this.renderRequestDescription(request)}</td>
                    {this.renderRequestStatus(request.requestStatusId)} 
                    <td>
                      {request.requestStatusId === 1 && (
                        <>
                          <button
                            onClick={() => this.handleApprove(request.requestTypeId, request.id)}
                            className="btn btn-primary mr-2"
                          >
                            Approve
                          </button>
                          <button
                            onClick={() => this.handleDecline(request.requestTypeId, request.id)}
                            className="btn btn-error"
                          >
                            Decline
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );

    return <>{renderRequestsTable}</>;
  }
}
