import React, { useState } from 'react';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { useApiRequests } from '../../services/fetchApiServices/index';

export const GoogleAuthForm = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const apiRequests = useApiRequests();
  const handleSuccess = async (credentialResponse) => {
    localStorage.setItem(
      "token",
      JSON.stringify(credentialResponse.credential)
    );

    var userObject = jwt_decode(credentialResponse.credential);
    const User = await apiRequests.getUserByEmail(userObject.email);
    
    if (User) {
      localStorage.setItem('userType', User.data.type)
      setIsAuthenticated(true);
      window.location.replace('/');
    } else {
      handleLogout();
    }
    
  };

  const onLogoutSuccess = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    setIsAuthenticated(false);
  };

  const handleError = () => {
    console.log("Login Failed");
  };

  const handleLogout = () => {
    googleLogout();
    onLogoutSuccess();
  };

  return (
    <section className="py-24 py-lg-36">
      <div className="text-center mx-auto px-3">
        <h2 className="mb-12 mt-6">Sign In to your account</h2>
        {isAuthenticated ? (
          <button onClick={handleLogout}>Logout</button>
        ) : (
          <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleError}
            render={(renderProps) => (
              <button className="" onClick={renderProps.onClick}></button>
            )}
          />
        )}
      </div>
    </section>
  );
};
