import React, { Component } from "react";
import { CustomSelect, NotificationPopup, LoadingSpinner } from "../../common";
import { formTransactionOptionTypes } from "../../services";
import {
  SendKudosFromAdminToEmployeeTransaction,
  ProductChoiceTransaction,
  SendKudosBetweenColleaguesTransaction,
  RequestKudosTransaction
} from "../";

export class TransactionShopForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProductForPurchase: "",
      selectedReceiverEmployee: "",
      selectedSenderEmployee: "",
      selectedChoiceOfTransaction: "",
      isDataFetching: true,
      amountOfKudos: 0,
      reason: "",
      isModalOpen: false,
      isLoading: false,
      isPopupOpen: false,
      isFormOpenned: false,
      userType: localStorage.getItem('userType'),
    };
    this.clearInputs = this.clearInputs.bind(this);
    this.handleModalToggle = this.handleModalToggle.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleSelectChange = (event) => {
    this.setState({
      selectedChoiceOfTransaction: event.target.value,
      isFormOpenned: true,
    });
  };

  handleModalToggle = (event) => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  handlePopupOpen = () => {
    this.setState({ isPopupOpen: true });
  };

  handlePopupClose = () => {
    this.setState({ isPopupOpen: false });
  };

  clearInputs = () => {
    this.setState({ isLoading: true }, () => {
      setTimeout(() => {
        this.setState(
          {
            selectedProductForPurchase: "",
            selectedReceiverEmployee: "",
            selectedSenderEmployee: "",
            selectedChoiceOfTransaction: "",
            isDataFetching: true,
            amountOfKudos: 0,
            reason: "",
            isModalOpen: false,
            isLoading: false,
            isFormOpenned: false,
          },
          () => {
            this.handlePopupOpen();
            setTimeout(() => {
              this.handlePopupClose();
            }, 1500);
          }
        );
      }, 300);
    });
  };

  render() {
    const showTransactionFormByType = () => {
      if (this.state.selectedChoiceOfTransaction !== "") {
        switch (this.state.selectedChoiceOfTransaction) {
          case "sendKudosBetweenColleagues":
            return (
              <SendKudosBetweenColleaguesTransaction
                userData={this.props.userData}
                handleModalToggle={this.handleModalToggle}
                handleModalClose={this.handleModalClose}
                isModalOpen={this.state.isModalOpen}
                kudosItems={this.props.kudosItems}
                clearInputs={this.clearInputs}
                isLoading={this.state.isLoading}
                isPopupOpen={this.state.isPopupOpen}
              />
            );
            break;
          case "productChoice":
            return (
              <ProductChoiceTransaction
                handleModalToggle={this.handleModalToggle}
                userData={this.props.userData}
                handleModalClose={this.handleModalClose}
                isModalOpen={this.state.isModalOpen}
                kudosItems={this.props.kudosItems}
                clearInputs={this.clearInputs}
                isLoading={this.state.isLoading}
                isPopupOpen={this.state.isPopupOpen}
              />
            );
            break;
          case "sendKudosAsAdmin":
            return (
              <SendKudosFromAdminToEmployeeTransaction
                handleModalToggle={this.handleModalToggle}
                userData={this.props.userData}
                handleModalClose={this.handleModalClose}
                isModalOpen={this.state.isModalOpen}
                kudosItems={this.props.kudosItems}
                clearInputs={this.clearInputs}
                isLoading={this.state.isLoading}
                isPopupOpen={this.state.isPopupOpen}
              />
            );
            case "requestKudos":
              if(this.state.userType == 2){
                return (
                  <RequestKudosTransaction
                    handleModalToggle={this.handleModalToggle}
                    userData={this.props.userData}
                    handleModalClose={this.handleModalClose}
                    isModalOpen={this.state.isModalOpen}
                    kudosItems={this.props.kudosItems}
                    clearInputs={this.clearInputs}
                    isLoading={this.state.isLoading}
                    isPopupOpen={this.state.isPopupOpen}
                  />
                );
            }
        }
      }
    };

    return (
      <>
        <div id="myForm" className="col-xl-4 col-sm-5 col-xs-8">
          <div className="d-flex flex-column">
          <CustomSelect
              handleSelectChange={this.handleSelectChange}
              optionTypes={formTransactionOptionTypes}
              userType={this.state.userType} // Pass userType to CustomSelect component
          />
            {this.state.selectedChoiceOfTransaction !== "" &&
              showTransactionFormByType()}
            {this.state.isLoading && <LoadingSpinner />}
            <NotificationPopup
              message="Transaction was made successfuly"
              isPopupOpen={this.state.isPopupOpen}
            />
          </div>
        </div>
      </>
    );
  }
}
