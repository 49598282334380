import React, { Component } from "react";
export class CustomModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.isModalOpen) {
      return null;
  }
  return (
    <div id="helpModal" className="modal modal-help fade in example-modal" tabIndex="-1" role="dialog" aria-labelledby="modal-title-2" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <button type="button" onClick={this.props.handleModalClose} className="close" data-toggle="modal" data-target="#helpModal" aria-label="Close"></button>
                <h4 id="modal-title-2" className="modal-title">{this.props.message}</h4>
            </div>
            <div className="modal-body">
                <p>In a case if you made a misclick, click on the "No" button, please</p>
            </div>
            <div className="modal-footer">
                <button type="submit" key="submit" className="btn btn-primary" onClick={this.props.handleSubmitForm} data-toggle="modal" data-target="#helpModal">Yes</button>
                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#helpModal" onClick={this.props.handleModalClose}>No</button>
            </div>
        </div>
    </div>
</div>
  );
  }
}
