import React, { Component } from "react";
import { useApiRequests } from "../../services/fetchApiServices/index";
import { CustomModal, LoadingSpinner } from "../../common";

export class FormAddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newProductName: "",
      newProductPrice: 0,
      newProductComment: "",
      newProductImageUrl: "",
      isNewProductAvailable: false,
      isModalOpen: false,
      isFormOpened: false,
      isLoading: this.props.isLoading,
    };
  }

  onNewProductNameChange = (event, value) => {
    this.setState({
      newProductName: event.target.value,
    });
  };

  onNewProductPriceChange = (event, value) => {
    this.setState({
      newProductPrice: parseInt(event.target.value),
    });
  };
  onNewProductCommentChange = (event, value) => {
    this.setState({
      newProductComment: event.target.value,
    });
  };
  onNewProductImageUrlChange = (event, value) => {
    this.setState({
      newProductImageUrl: event.target.value,
    });
  };
  onIsNewProductAvailableChange = (event, value) => {
    if (event.target.value === "Yes") {
      this.setState({
        isNewProductAvailable: true,
      });
    } else {
      this.setState({
        isNewProductAvailable: false,
      });
    }
  };

  handleModalToggle = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  addNewProductButton = () => {
    this.setState({ isFormOpened: true });
  };

  handleNewProductButtonSubmit = () => {
    this.handleModalToggle();
  };

  handleResetForm = () => {
    this.setState({
      newProductName: "",
      newProductPrice: 0,
      newProductComment: "",
      isNewProductAvailable: false,
      newProductImageUrl: "",
    });
  };

  handleCloseForm = () => {
    this.setState({
      isFormOpened: false,
    });
    this.handleResetForm();
  };

  handleAddNewProduct = async () => {
    try {
      const apiRequests = useApiRequests(); // Use the useApiRequests function to get API functions

      const response = await apiRequests.postAddNewProducs(
        this.state.newProductName,
        this.state.newProductPrice,
        this.state.newProductComment,
        this.state.isNewProductAvailable,
        this.state.newProductImageUrl
      );

      if (response.status === 200) {
        this.props.fetchData();
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  handleSubmitForm = () => {
    this.handleResetForm();
    this.setState({ isLoading: true, isModalOpen: false }, () => {
      setTimeout(() => {
        this.setState({
          newProductName: "",
          newProductPrice: 0,
          newProductComment: "",
          isNewProductAvailable: false,
          isLoading: false,
        });
      }, 700);
    });
    this.handleAddNewProduct();
  };

  render() {
    return (
      <>
        {this.state.isFormOpened ? (
          <>
            <CustomModal
              handleModalClose={this.handleModalClose}
              handleSubmitForm={this.handleSubmitForm}
              isModalOpen={this.state.isModalOpen}
              message={`Do you want to add new product: ${this.state.newProductName}?`}
            />
            <div className="form-horizontal col-xxl-6 col-xl-7 col-md-8 col-sm-8 col-xs-11">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-xl-4 col-sm-5 col-xs-5 control-label"
                >
                  Product Name:
                </label>
                <div className="col-xl-8 col-sm-7 col-xs-7">
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    id="name"
                    value={this.state.newProductName}
                    onChange={this.onNewProductNameChange}
                    placeholder="Product Name"
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="price"
                  className="col-xl-4 col-sm-5 col-xs-5 control-label"
                >
                  Price:
                </label>
                <div className="col-xl-8 col-sm-7 col-xs-7">
                  <input
                    className="form-control"
                    name="price"
                    id="price"
                    type="number"
                    value={this.state.newProductPrice}
                    onChange={this.onNewProductPriceChange}
                    placeholder="Price"
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="comment"
                  className="col-xl-4 col-sm-5 col-xs-5 control-label"
                >
                  Comment:
                </label>
                <div className="col-xl-8 col-sm-7 col-xs-7">
                  <textarea
                    className="form-control"
                    name="comment"
                    id="comment"
                    value={this.state.newProductComment}
                    onChange={this.onNewProductCommentChange}
                    placeholder="Comment"
                    maxLength={255}
                  />
                </div>
              </div>
              <div className="form-group">
              <label
                htmlFor="image"
                className="col-xl-4 col-sm-5 col-xs-5 control-label"
              >
              Image Link:
              </label>
                <div className="col-xl-8 col-sm-7 col-xs-7">
                <input
                  className="form-control"
                  name="image"
                  id="image"
                  type="text"
                  maxLength={255}
                  placeholder="Image"
                  value={this.state.newProductImageUrl}
                  onChange={this.onNewProductImageUrlChange}
                />
              </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="comment"
                  className="col-xl-4 col-sm-5 col-xs-5 control-label"
                >
                  Is product available?:
                </label>
                <select
                  className="form-control col-xl-8 col-sm-7 col-xs-7 text-center"
                  onChange={this.onIsNewProductAvailableChange}
                >
                  <option defaultValue>No</option>
                  <option>Yes</option>
                </select>
              </div>
              <div className="form-group d-flex justify-content-between justify-content-sm-end align-items-xs-around">
                <button
                  type="button"
                  className="btn"
                  onClick={this.handleResetForm}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={this.handleCloseForm}
                >
                  Close
                </button>
                <button
                  type="button"
                  className={
                    this.state.newProductName.length &&
                    this.state.newProductPrice > 0
                      ? `btn btn-primary`
                      : `btn disabled`
                  }
                  onClick={this.handleModalToggle}
                >
                  Add Product
                </button>
              </div>
            </div>
            {this.state.isLoading && <LoadingSpinner />}
          </>
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.addNewProductButton}
          >
            Add new product
          </button>
        )}
      </>
    );
  }
}
