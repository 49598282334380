import axios from "axios";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (response) => {
    console.log(response);
    if(response)
    {
      const credentialResponse = JSON.parse(localStorage.getItem('token'));
      if (credentialResponse) {
        response.headers['Authorization'] = `Bearer ${credentialResponse}`;
      }
      return response;
    }
},
(error) => { 
  return Promise.reject(error);
}
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => { 
    // Check if the status is 0 and ignore the error
    if (error.request && error.request.status === 401) {
      localStorage.removeItem('token');
      window.location.replace("/login");
    }else{
      return Promise.reject(error);
    }
  }
);

export const useApiRequests = () => {
  const getAllUsers = () => {
    try {
      const response = axiosInstance.get(`${process.env.REACT_APP_API_KEY}/users`);
      return response;
    } catch (error) {
    }
  };

  const getAllUsersPaginated = (pageNumber) => {
    try {
      const response = axiosInstance.get(`${process.env.REACT_APP_API_KEY}/users/userpage/${pageNumber}`);
      return response;
    } catch (error) {
    }
  };

  const getAdminHistoryLog = () => {
    try {
      const response = axiosInstance.get(`${process.env.REACT_APP_API_KEY}/adminactionhistorylog`);
      return response;
    } catch (error) {
    }
  };

  const getUserById = (id) => {
    try {
      const response = axiosInstance.get(`${process.env.REACT_APP_API_KEY}/users/getkudos/${id}`);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const getUserByEmail = (email) => {
    try {
      const response = axiosInstance.get(`${process.env.REACT_APP_API_KEY}/Users/getuser/${email}`);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const getUserHistoryLogById = (id) => {
    try {
      const response = axiosInstance.get(`${process.env.REACT_APP_API_KEY}/usershistorylog/${id}`);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllUserInformation = async (id) => {
    try {
      const [userMainInfoResponse, userHistoryLogResponse] = await Promise.all([getUserById(id), getUserHistoryLogById(id)]);
      const UsersInformation = userMainInfoResponse.data;
      const HistoryLogResponse = userHistoryLogResponse.data;
      return { UsersInformation, HistoryLogResponse };
    }catch (error) {
      alert(error.message);
      return error;
    }
  };

  const getAllProducts = () => {
    try {
      const response = axiosInstance.get(`${process.env.REACT_APP_API_KEY}/shopitems`);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  
  const getNonHiddenProducts = () => {
    try {
      const response = axiosInstance.get(`${process.env.REACT_APP_API_KEY}/shopitems/nonhiddenproducts`);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllRequests = () => {
    try {
      const response = axiosInstance.get(`${process.env.REACT_APP_API_KEY}/requests`);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllShoppingPageInformation = async () => {
    try {
      const [usersResponse, productsResponse] = await Promise.all([getAllUsers(), getNonHiddenProducts()]);
      const allUsersInformation = usersResponse.data;
      const allNonHiddenShoppingItemsInformation = productsResponse.data;
      return { allUsersInformation, allNonHiddenShoppingItemsInformation };
      //add here map
    } catch (error) {
      console.error(error);
      return error;
    }
  };
  
  
  const postRegisterPurchase = (productId) => {
    try {
      const response = axiosInstance.post(
        `${process.env.REACT_APP_API_KEY}/registerpurchase`,
        {
          productId: productId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const postSendKudosFromAdminToEmployee = (userId, kudosAmount, transactionReason) => {
    try {
      const response = axiosInstance.post(
        `${process.env.REACT_APP_API_KEY}/kudosfromvisma`,
        {
          userId: userId,
          kudosAmount: Number(kudosAmount),
          reason: transactionReason,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const postSendKudosFromEmployeeToEmployee = (receiverId, amountOfKudos, transactionReason) => {
    try {
      const response = axiosInstance.post(
        `${process.env.REACT_APP_API_KEY}/persontopersontransaction`,
        {
          receiverId: receiverId,
          kudosAmount: Number(amountOfKudos),
          reason: transactionReason,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const postAddNewUser = (userName, userSurname) => {
    try {
      const response = axiosInstance.post(
        `${process.env.REACT_APP_API_KEY}/users/add`,
        {
          name: userName,
          surname: userSurname,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteUserById = (userId) => {
    try {
      const response = axiosInstance.delete(
        `${process.env.REACT_APP_API_KEY}/users/delete?userId=${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const editUserById = (userId, newUserName, newUserSurname) => {
    try {
      const response = axiosInstance.put(
        `${process.env.REACT_APP_API_KEY}/users/edit`,
        {
          userId: userId,
          name: newUserName,
          surname: newUserSurname,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const postAddNewProducs = (newProductName, newProductPrice, newProductComment, isNewProductAvailable, newProductImageUrl) => {
    try {
      const response = axiosInstance
        .post(
          `${process.env.REACT_APP_API_KEY}/shopitems/add`,
          {
            productId: 0,
            productName: newProductName,
            productPrice: newProductPrice,
            productComment: newProductComment,
            productIsAvailable: isNewProductAvailable,
            productImageUrl: newProductImageUrl,
          },
          {
            headers: {
              "content-type": "text/json",
            },
          }
        )
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  const editProduct = (productId, newName, newPrice, newComment, newAvailability, newProductImageUrl) => {
    try {
      const response = axiosInstance.put(
        `${process.env.REACT_APP_API_KEY}/shopitems/edit`,
        {
          id: productId,
          productName: newName,
          productPrice: newPrice,
          productComment: newComment,
          productIsAvailable: newAvailability,
          productImageUrl: newProductImageUrl,
        }
      );
      return response;
    } catch (error) {
      console.log(error.message);
      return Promise.reject(error);
    }
  };

  const deleteProductById = (productId) => {
    try {
      const response = axiosInstance.delete(`${process.env.REACT_APP_API_KEY}/ShopItems/delete?productId=${productId}`);
      return response;
    } catch (error) {
      console.log(error.message);
      return Promise.reject(error);
    }
  };

  const approveRequestByTypeAndId = (requestTypeId, id) => {
    try {
      const response = axiosInstance.put(`${process.env.REACT_APP_API_KEY}/requests/approve/${requestTypeId}/${id}`);
      return response;
    } catch (error) {
      console.log(error.message);
      return Promise.reject(error);
    }
  }

  const rejectRequestByTypeAndId = (requestTypeId, id) => {
    try {
      const response = axiosInstance.put(`${process.env.REACT_APP_API_KEY}/requests/reject/${requestTypeId}/${id}`);
      return response;
    } catch (error) {
      console.log(error.message);
      return Promise.reject(error);
    }
  }

  const requestKudos = (kudosAmount, reason) => {
    try {
      const response = axiosInstance.post(`${process.env.REACT_APP_API_KEY}/RequestKudos`,{
        kudosAmount: kudosAmount,
        reason: reason
      });
      return response;
    } catch (error) {
      console.log(error.message);
      return Promise.reject(error);
    }
  }

  const exportData = async () => {
    try {
      const response = await axiosInstance({
        url: `${process.env.REACT_APP_API_KEY}/importexport`,
        method: "GET",
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "KudoShopInfo.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error.message);
    }
  };


  return {
    getAllUsers,
    getAllUsersPaginated,
    getAdminHistoryLog,
    getAllUserInformation,
    getUserByEmail,
    getAllShoppingPageInformation,
    postRegisterPurchase,
    postSendKudosFromAdminToEmployee,
    postSendKudosFromEmployeeToEmployee,
    postAddNewUser,
    getAllProducts,
    getNonHiddenProducts,
    getAllRequests,
    postAddNewProducs,
    deleteUserById,
    editProduct,
    deleteProductById,
    approveRequestByTypeAndId,
    rejectRequestByTypeAndId,
    requestKudos,
    editUserById,
    exportData
  };
};
