import React, { Component } from "react";
import "./styles.scss";

export class Facade extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="container">
        <div className="row facade d-flex flex-column align-items-center">
          {this.props.children}
        </div>
      </div>
    );
  }
}
